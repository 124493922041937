<template>
  <v-content>
    <v-container fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex>
          <iframe
            style="width:100%;height:500px;overflow:hidden;"
            src="https://florista.cl/google/index.php"
            frameborder="0"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
export default {}
</script>

<style></style>
